import React, { useState } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import jwt_decode from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, Transition } from "@headlessui/react"; // Para el modal


// Modal para añadir anotación
function AddAnnotationModal({ isOpen, onClose, idExpedient, usuario }) {
  const [anotacion, setAnotacion] = useState("");
  const [tarea, setTarea] = useState('No hay tarea');
  const [loading, setLoading] = useState(false);

  const handleAddAnnotation = async (e) => {
    e.preventDefault();

    if (!anotacion.trim()) {
      toast.error("La anotación no puede estar vacía");
      return;
    }

    const infoNote = {
      expedient_id: idExpedient,
      anotacion,
      fecha: new Date(),
      user_id: usuario.id,
      creator_name: usuario.name,
      creator_role: usuario.role,
      tarea,
    };

    try {
      setLoading(true);
      await axios.post(`${baseAPI}/annotations/`, infoNote);
      toast.success("Anotación añadida con éxito");
      setAnotacion("");
      setTarea("");
      onClose(); // Cierra el modal tras añadir
    } catch (error) {
      console.error(error);
      toast.error("Error al añadir la anotación");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-6">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all">
                <Dialog.Title className="text-lg font-medium text-gray-900">
                  Añadir nueva anotación
                </Dialog.Title>
                <form className="mt-4" onSubmit={handleAddAnnotation}>
                  <div className="mb-4">
                    <label className="block text-sm font-semibold text-gray-700">
                      Nueva anotación
                    </label>
                    <textarea
                      className="w-full mt-2 p-3 rounded-md border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      rows="4"
                      placeholder="Escribe aquí tu anotación"
                      value={anotacion}
                      onChange={(e) => setAnotacion(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center mb-5">
                    <label className="block text-sm font-semibold text-gray-700 mr-3">
                      {usuario.role === "admin"
                        ? "¿Es una tarea para el cliente?"
                        : "¿Es una tarea para el equipo?"}
                    </label>
                    <input
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600"
                      checked={tarea === "Pendiente"}
                      onChange={() =>
                        setTarea((prev) =>
                          prev === "Pendiente" ? "" : "Pendiente"
                        )
                      }
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className={`w-full py-2 px-4 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 transition ${
                      loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    {loading ? "Añadiendo..." : "Añadir anotación"}
                  </button>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
// Modal para el formulario de enviar email
function EmailModal({ isOpen, onClose, onSend, subject, setSubject, message, setMessage }) {
  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="bg-white rounded-lg shadow-xl transform transition-all sm:max-w-lg sm:w-full">
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-4">Enviar Email Personalizado</h3>
                <form onSubmit={onSend}>
                  <div className="mb-4">
                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                      Asunto:
                    </label>
                    <input
                      type="text"
                      id="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                      className="mt-1 w-full border-gray-300 rounded-md p-2 shadow-sm"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                      Mensaje:
                    </label>
                    <textarea
                      id="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      rows="4"
                      required
                      className="mt-1 w-full border-gray-300 rounded-md p-2 shadow-sm"
                    ></textarea>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      className="text-gray-500 hover:text-gray-700"
                      onClick={onClose}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md"
                    >
                      Enviar Email
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// Modal para el formulario de enviar WhatsApp
function WhatsAppModal({ isOpen, onClose, onSend, message, setMessage }) {
  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="bg-white rounded-lg shadow-xl transform transition-all sm:max-w-lg sm:w-full">
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-4">
                  Enviar Mensaje de WhatsApp
                </h3>
                <form onSubmit={onSend}>
                  <div className="mb-4">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                      Mensaje:
                    </label>
                    <textarea
                      id="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      rows="4"
                      required
                      className="mt-1 w-full border-gray-300 rounded-md p-2 shadow-sm"
                    ></textarea>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      className="text-gray-500 hover:text-gray-700"
                      onClick={onClose}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md"
                    >
                      Enviar WhatsApp
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}



export default function ActionsOptions({ expedientId, debtorId }) {
  const userToken = localStorage.getItem("token");
  const decodedUser = jwt_decode(userToken);


  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isWhatsAppModalOpen, setIsWhatsAppModalOpen] = useState(false);

  const [isAddAnnotationModalOpen, setIsAddAnnotationModalOpen] = useState(false);



  const handleSendEmail = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseAPI}/actions/sendemailwithouttemplate`, {
        expedientId,
        userSesion: decodedUser,
        textEmail: emailMessage,
        subjectEmail: emailSubject,
      });
      toast.success("Correo enviado exitosamente!");
      setEmailMessage("");
      setEmailSubject("");
      setIsEmailModalOpen(false);
    } catch (error) {
      console.error("Error al enviar el email:", error);
      toast.error("Error al enviar el correo");
    }
  };

  const handleSendWhatsApp = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseAPI}/actions/sendwhatsapp`, {
        expedientId,
        debtorId,
        message: whatsAppMessage,
        userSesion: decodedUser,
      });
      toast.success("Mensaje de WhatsApp enviado correctamente!");
      setWhatsAppMessage("");
      setIsWhatsAppModalOpen(false);
    } catch (error) {
      console.error("Error al enviar el WhatsApp:", error);
      toast.error("Error al enviar el mensaje de WhatsApp");
    }
  };

  const handleSendSMS = async () => {
    if (window.confirm("¿Seguro que quieres enviar un SMS?")) {
      try {
        await axios.post(`${baseAPI}/actions/sendsmstodebtor`, {
          expedientId,
          debtorId,
          userSesion: decodedUser,
        });
        toast.success("SMS enviado correctamente 📱");
      } catch (error) {
        console.error("Error enviando el SMS:", error);
        toast.error("Error al enviar el SMS 😞");
      }
    }
  };

  const handleCallToDebtor = async () => {
    if (window.confirm("¿Seguro que quieres realizar una llamada?")) {
      try {
        await axios.post(`${baseAPI}/actions/calltodebtor`, {
          expedientId,
          debtorId,
          userSesion: decodedUser,
        });
        toast.success("Llamada realizada correctamente 📞");
      } catch (error) {
        console.error("Error realizando la llamada:", error);
        toast.error("Error al realizar la llamada 😞");
      }
    }
  };



 return (
    <>
      <ToastContainer />
      <div className="flex space-x-4">
        <button
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          onClick={() => setIsEmailModalOpen(true)}
        >
          Enviar Email
        </button>
        <button
          className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
          onClick={() => setIsWhatsAppModalOpen(true)}
        >
          Enviar WhatsApp
        </button>
        <button
          className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600"
          onClick={handleSendSMS}
        >
          Enviar SMS
        </button>
        <button
          className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
          onClick={handleCallToDebtor}
        >
          Llamar
        </button>
        <button
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          onClick={() => setIsAddAnnotationModalOpen(true)}
        >
          Añadir Anotación
        </button>
      </div>

      {/* Modales */}
      <EmailModal
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        onSend={handleSendEmail}
        subject={emailSubject}
        setSubject={setEmailSubject}
        message={emailMessage}
        setMessage={setEmailMessage}
      />
      <WhatsAppModal
        isOpen={isWhatsAppModalOpen}
        onClose={() => setIsWhatsAppModalOpen(false)}
        onSend={handleSendWhatsApp}
        message={whatsAppMessage}
        setMessage={setWhatsAppMessage}
      />
        <AddAnnotationModal
          isOpen={isAddAnnotationModalOpen}
          onClose={() => setIsAddAnnotationModalOpen(false)}
          idExpedient={expedientId}
          usuario={decodedUser}
        />
   
    </>
  );
}