import React, { useState } from "react";

function AnalyticsCard06({ estadosAgrupados }) {
  const [mostrarImporte, setMostrarImporte] = useState(false);

  
  // Iconos por estado
  // Se pueden añadir más iconos si se desea
  const iconosPorEstado = {
    "On going": "🔄",
    "Email opened": "📧",
    "Hot": "🔥",
    "Cold": "❄️",
    "Fallido": "⚠️",
    "Ilocalizable": "🚫",
    "Sin datos de contacto": "📭",
    "Cancelled": "❌",
    "Dispute": "⚔️",
    "Burofax Enviado": "📨",
    "Burofax con resultado negativo": "❌",
    "Burofax con resultado positivo": "✔️",
    "Demanda pendiente": "📅",
    "Demanda preparada": "📝",
    "Demanda presentada": "📄",
    "Demanda Admitida": "📑",
    "Demanda No Admitida": "⛔",
    "Oposición del Deudor": "✋",
    "Pendiente Requerimiento": "📩",
    "Requerimiento Judicial Positivo": "✔️",
    "Pendiente Ejecucion": "⏳",
    "Sentencia Estimatoria": "✅",
    "Sentencia Desestimatoria": "❌",
    "Sentencia Parcial": "⚖️",
    "Cobrado": "💰",
    "Cobrado Judicialmente": "⚖️"
};
  

  const toggleView = () => {
    setMostrarImporte(!mostrarImporte);
  };

  // Ordenar según el estado actual
  const estadosOrdenados = mostrarImporte
    ? [...estadosAgrupados].sort(
        (a, b) => b.totalImporteDeuda - a.totalImporteDeuda
      )
    : estadosAgrupados;

  // Encontrar el valor máximo para calcular el ancho proporcional
  const maxValor = mostrarImporte
    ? Math.max(...estadosOrdenados.map((e) => e.totalImporteDeuda))
    : Math.max(...estadosOrdenados.map((e) => e.cantidad));

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100 flex justify-between items-center">
        <h2 className="font-semibold text-slate-800">Funnel</h2>
        <button
          onClick={toggleView}
          className="px-3 py-1 text-xs font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors"
        >
          {mostrarImporte ? "Mostrar Cantidad" : "Mostrar Importe"}
        </button>
      </header>
      <div className="grow p-4">
        <div className="flex flex-col h-full">
          <div className="grow">
            <ul className="flex justify-between text-xs uppercase text-gray-500 font-semibold px-2 mb-2">
              <li>Estado</li>
              <li>{mostrarImporte ? "Importe Deuda" : "Nº Expedientes"}</li>
            </ul>
            <ul className="space-y-2 text-sm text-gray-800">
              {estadosOrdenados.map(
                ({ estado, cantidad, totalImporteDeuda }) => (
                  <li
                    className="relative px-2 py-2 bg-gray-50 rounded-md shadow-sm hover:bg-gray-100 transition-all duration-200"
                    key={estado}
                  >
                    <div
                      className="absolute inset-0 bg-blue-200 rounded-md"
                      aria-hidden="true"
                      style={{
                        width: `${
                          (mostrarImporte ? totalImporteDeuda : cantidad) /
                          maxValor *
                          100
                        }%`, // Ajustar el ancho de manera proporcional
                      }}
                    ></div>
                    <div className="relative flex justify-between space-x-4 items-center">
                      <div className="flex items-center space-x-2">
                        <span>{iconosPorEstado[estado] || "❓"}</span>
                        <span>{estado}</span>
                      </div>
                      <div className="font-medium">
                        {mostrarImporte
                          ? `${totalImporteDeuda.toLocaleString("es-ES", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} €`
                          : cantidad}
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard06;
