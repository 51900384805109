  import React, { useState, useEffect } from "react";
  import axios from "axios";
  import { baseAPI } from "../config";
  import CurrencyFormat from "react-currency-format";
  import moment from "moment";
  import Sidebar from "../partials/Sidebar";
  import Header from "../partials/Header";
  import Footer from "../partials/Footer";
  import WelcomeBanner from "../partials/components/WelcomeBanner";
  import NotesExpedient from "../partials/components/Notes";
  import ActionsOptions from "../partials/actions/ActionsOptions";

  import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react"; // Usa HeadlessUI para el modal



  import { EyeIcon, EyeSlashIcon, PencilIcon } from "@heroicons/react/20/solid";

  // Función para aplicar estilos condicionales a Total Interacciones
  const getInteractionStyle = (totalInteractions) => {
    if (totalInteractions > 15) {
      return "bg-red-100 text-red-600"; // Fondo rojo claro y texto rojo
    } else if (totalInteractions >= 5) {
      return "bg-green-100 text-green-600"; // Fondo verde claro y texto verde
    } else {
      return "bg-orange-100 text-orange-600"; // Fondo naranja claro y texto naranja
    }
  };

  const Interactions = ({ interaction, toggleIsDone }) => {

    const [showDetails, setShowDetails] = useState(false); 
    const [visibleAnnotations, setVisibleAnnotations] = useState(10);
    const [visibleInteractions, setVisibleInteractions] = useState(10);


// Define lógica para los modales


    const formattedDate = interaction.createdAt
      ? moment(interaction.createdAt).format("DD/MM/YYYY")
      : "No Date";
    const importeDeudaFormatted = interaction.importe_deuda / 1;

// Cargar más anotaciones
const loadMoreAnnotations = () => {
  setVisibleAnnotations((prev) => prev + 10);
};

// Cargar más interacciones
const loadMoreInteractions = () => {
  setVisibleInteractions((prev) => prev + 10);
};



return (
  <>
    <tr>
      <td className="px-3 py-2 whitespace-nowrap">
        <div className="text-left text-gray-800">{formattedDate}</div>
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <div className="text-left text-gray-800">{interaction.nombre_comercial}</div>
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <div className="text-left text-gray-800">
          {interaction.deudores && interaction.deudores.length > 0
            ? interaction.deudores[0].debtorname
            : "Sin deudor"}
        </div>
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <div className="text-left text-gray-800">
          <CurrencyFormat
            value={importeDeudaFormatted}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            suffix={" €"}
            decimalScale={2}
          />
        </div>
      </td>
      <td className="px-3 py-2 w-[70px] whitespace-nowrap text-center">
        <div
          className={`text-center font-semibold px-2 py-1 rounded ${getInteractionStyle(
            interaction.total_interactions
          )}`}
        >
          {interaction.total_interactions}
          {interaction.total_interactions > 15 && " 🔥"}
        </div>
      </td>
      <td className="px-3 py-2 whitespace-nowrap text-center">
        <label className="flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={interaction.isDone === true}
            onChange={() => toggleIsDone(interaction.expedient_id, interaction.isDone)}
            className="hidden"
          />
          <span
            className={`w-10 h-5 flex items-center flex-shrink-0 p-1 rounded-full ${
              interaction.isDone ? "bg-green-400" : "bg-gray-300"
            }`}
          >
            <span
              className={`w-4 h-4 bg-white rounded-full shadow transform transition-transform ${
                interaction.isDone ? "translate-x-5" : "translate-x-0"
              }`}
            ></span>
          </span>
        </label>
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <div className="flex space-x-2 items-center">
        <button
  className="text-indigo-500 hover:text-indigo-600"
  onClick={() => window.open(`/view/${interaction.expedient_id}`, '_blank')}
>
  <PencilIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
</button>

          <button
            className="text-indigo-500 hover:text-indigo-600"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? (
              <EyeSlashIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
            ) : (
              <EyeIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
            )}
          </button>
        </div>
      </td>
    </tr>

    {showDetails && (
      <tr>
        <td colSpan="5" className="bg-gray-50">
          <div className="p-4 border-t border-gray-200 rounded-b-lg space-y-4">

         {/* Nueva cajita */}
<div className="text-indigo-700 p-4 rounded-md shadow-md flex flex-col items-center space-y-2">
  <h4 className="font-semibold text-lg">Detalle deuda</h4>
  {interaction.detalle_deuda && interaction.detalle_deuda.length > 0 ? (
    <p className="text-sm text-center">
      <ul className="list-disc list-inside text-sm text-gray-700">
        {interaction.detalle_deuda.map((detalle, index) => (
          <li key={index} className="mb-1">
            <CurrencyFormat
              value={detalle.importe}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              suffix={" €"}
              decimalScale={2}
              renderText={(value) => <>{value}</>}
            />
            <span className="ml-2 text-xs text-gray-500">
              {detalle.numero_factura || "Sin número de factura"}
            </span>
          </li>
        ))}
      </ul>
    </p>
  ) : (
    <p className="text-sm text-gray-500 text-center">
      No hay detalles de deuda disponibles.
    </p>
  )}
  <div className="flex space-x-4">
    <ActionsOptions
      expedientId={interaction.expedient_id}
      debtorId={
        interaction.deudores && interaction.deudores.length > 0
          ? interaction.deudores[0].debtorid
          : null
      }
    />
  </div>
</div>

        {/* 📌 Anotaciones */}
        {/* < NotesExpedient expedientId={interaction.expedient_id} /> */}
{interaction.annotations.length > 0 ? (
  <>
    <h3 className="font-semibold text-gray-800">
      📌 Anotaciones ({interaction.annotations.length})
    </h3>
    <ul className="list-disc list-inside text-gray-700">
      {interaction.annotations
        .sort((a, b) => new Date(b.fecha) - new Date(a.fecha))  // 🔥 Ordenar de más reciente a más antiguo
        .slice(0, visibleAnnotations)
        .map((annotation) => (
          <li key={annotation._id} className="text-sm">
            ✏️ <strong>{annotation.anotacion}</strong> (
            {moment(annotation.fecha).format("DD/MM/YYYY")})
          </li>
        ))}
    </ul>
    {visibleAnnotations < interaction.annotations.length && (
      <button
        onClick={loadMoreAnnotations}
        className="mt-2 text-indigo-600 hover:underline text-sm"
      >
        Ver más anotaciones...
      </button>
      
    )}

{visibleAnnotations > 10 && (
        <button
          onClick={() =>
            setVisibleInteractions((prev) => Math.max(prev - 10, 10))
          }
          className="text-red-600 hover:underline text-sm"
        >
          Ver menos interacciones...
        </button>
      )}
  </>
) : (
  <h3 className="font-semibold text-gray-800">
  📌 Anotaciones:
  <p className="text-sm text-gray-500"> No hay anotaciones.</p>

</h3>
  
)}

{/* 💬 Interacciones */}
{interaction.interactions.length > 0 ? (
  <>
    <h3 className="font-semibold text-gray-800">
      💬 Interacciones ({interaction.interactions.length})
    </h3>
    <ul className="list-disc list-inside text-gray-700 space-y-2">
      {interaction.interactions
        .sort((a, b) => new Date(b.date) - new Date(a.date)) // 🔥 Ordenar de más reciente a más antiguo
        .slice(0, visibleInteractions)
        .map((interactionItem) => (
          <li
            key={interactionItem.interaction_id}
            className={`text-sm p-2 rounded-md ${
              interactionItem.direction === "outgoing"
                ? "bg-blue-100 text-blue-800"
                : "bg-green-100 text-green-800"
            }`}
          >
            <strong>{interactionItem.type}</strong> -{" "}
            {moment(interactionItem.date).format("DD/MM/YYYY")} -{" "}
            {interactionItem.details?.message || "Sin detalles"}
          </li>
        ))}
    </ul>
    <div className="flex space-x-4 mt-2">
      {visibleInteractions < interaction.interactions.length && (
        <button
          onClick={loadMoreInteractions}
          className="text-indigo-600 hover:underline text-sm"
        >
          Ver más interacciones...
        </button>
      )}
      {visibleInteractions > 10 && (
        <button
          onClick={() =>
            setVisibleInteractions((prev) => Math.max(prev - 10, 10))
          }
          className="text-red-600 hover:underline text-sm"
        >
          Ver menos interacciones...
        </button>
      )}
    </div>
  </>
) : (
  <p className="text-sm text-gray-500">No hay interacciones.</p>
)}


          </div>
        </td>
      </tr>
    )}
  </>
);
};

const CompletedInteractionsModal = ({ isOpen, onClose, interactions, toggleIsDone }) => (
  <Transition.Root show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-6">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-lg bg-white shadow-xl transition-all">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-gray-900">
                    ✅ Interacciones Completadas
                  </h3>
                  <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    ✖
                  </button>
                </div>
                <div className="mt-4">
                  {interactions.length > 0 ? (
                    <table className="table-auto w-full text-left">
                      {/* Encabezado de la tabla */}
                      <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
                        <tr>
                          <th className="px-3 py-2">Fecha</th>
                          <th className="px-3 py-2">Cliente</th>
                          <th className="px-3 py-2">Deudor</th>
                          <th className="px-3 py-2">Importe Deuda</th>
                          <th className="px-3 py-2">Interacciones</th>
                          <th className="px-3 py-2">Estado</th>
                        </tr>
                      </thead>
                      {/* Cuerpo de la tabla */}
                      <tbody className="text-sm divide-y divide-gray-200">
                        {interactions.map((interaction) => (
                          <tr key={interaction.expedient_id}>
                            <td className="px-3 py-2">
                              {moment(interaction.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td className="px-3 py-2">{interaction.nombre_comercial}</td>
                            <td className="px-3 py-2">
                              {interaction.deudores?.[0]?.debtorname || "Sin deudor"}
                            </td>
                            <td className="px-3 py-2">
                              <CurrencyFormat
                                value={interaction.importe_deuda}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                suffix={" €"}
                                decimalScale={2}
                              />
                            </td>
                            <td className="px-3 py-2 text-center">
                              {interaction.total_interactions || 0}
                            </td>
                            <td className="px-3 py-2 text-center">
                              <label className="flex items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={interaction.isDone === true}
                                  onChange={() =>
                                    toggleIsDone(
                                      interaction.expedient_id,
                                      interaction.isDone
                                    )
                                  }
                                  className="hidden"
                                />
                                <span
                                  className={`w-10 h-5 flex items-center flex-shrink-0 p-1 rounded-full ${
                                    interaction.isDone
                                      ? "bg-green-400"
                                      : "bg-gray-300"
                                  }`}
                                >
                                  <span
                                    className={`w-4 h-4 bg-white rounded-full shadow transform transition-transform ${
                                      interaction.isDone
                                        ? "translate-x-5"
                                        : "translate-x-0"
                                    }`}
                                  ></span>
                                </span>
                              </label>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="text-gray-500">No hay interacciones completadas.</p>
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);



  const InteractionsList = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [interactions, setInteractions] = useState([]); // Estado para guardar las interacciones
    const [completedInteractions, setCompletedInteractions] = useState([]);
    const [modalOpen, setModalOpen] = useState(false); // Estado para abrir/cerrar modal

    const [loading, setLoading] = useState(true); // Estado para controlar la carga
    const [sortField, setSortField] = useState("createdAt"); // Campo para ordenar
    const [sortOrder, setSortOrder] = useState("asc"); // Orden asc o desc

    const [currentPageCompleted, setCurrentPageCompleted] = useState(1);
const [totalPagesCompleted, setTotalPagesCompleted] = useState(1);

    const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const itemsPerPage = 10;  // Límite de elementos por página

const [totalUnreadCount, setTotalUnreadCount] = useState(0);
const [totalDoneCount, setTotalDoneCount] = useState(0);




    const userToken = localStorage.getItem("token");

    if (userToken == null) {
      window.location = "/login";
    }

    // Llamada a la API para obtener las interacciones
    useEffect(() => {
      const fetchInteractions = async () => {
        try {
          setLoading(true); // Activa el estado de carga
          const response = await axios.get(
            `${baseAPI}/expedients/unread-interactions?page=${currentPage}&limit=${itemsPerPage}`
          );
          console.log("Expedients:", response.data.expedients);
      
          setInteractions(response.data.expedients);
          setTotalPages(response.data.totalPages);
          setTotalUnreadCount(response.data.totalUnreadCount);
        } catch (error) {
          console.error("Error fetching interactions:", error);
        } finally {
          setLoading(false); // Asegúrate de desactivar el estado de carga
        }
      };
    
      fetchInteractions();
    }, [userToken, currentPage, itemsPerPage]);
    
    useEffect(() => {
      const fetchCompletedInteractions = async () => {
        try {
          setLoading(true); // Activar el estado de carga
          const response = await axios.get(
            `${baseAPI}/expedients/done-interactions?page=${currentPageCompleted}&limit=${itemsPerPage}`
          );
          console.log("Interacciones Completadas:", response.data.expedients);
    
          setCompletedInteractions(response.data.expedients);
          setTotalPagesCompleted(response.data.totalPages);
          setTotalDoneCount(response.data.totalDoneCount);
        } catch (error) {
          console.error("Error fetching completed interactions:", error);
        } finally {
          setLoading(false); // Desactivar el estado de carga
        }
      };
    
      // Llama a la API solo si el modal está abierto
      if (modalOpen) {
        fetchCompletedInteractions();
      }
    }, [modalOpen, currentPageCompleted, itemsPerPage]);
    


    const toggleIsDone = async (expedientId, currentState) => {
      try {
        await axios.post(`${baseAPI}/expedients/update-isdone`, {
          expedient_id: expedientId,
          isDone: !currentState,
        });
    
        // Refrescar la página
        window.location.reload();
      } catch (error) {
        console.error("Error updating isDone", error);
      }
    };
    
    

    
     
    
    // Función para ordenar las interacciones
    const sortInteractions = (field) => {
      const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
      setSortField(field);
      setSortOrder(order);
    
      // Ordenar interacciones
      const sorted = [...interactions].sort((a, b) => {
        if (field === "createdAt") {
          return order === "asc"
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt);
        } else if (field === "importe_deuda") {
          return order === "asc"
            ? a.importe_deuda - b.importe_deuda
            : b.importe_deuda - a.importe_deuda;
        } else if (field === "total_interactions") {
          return order === "asc"
            ? a.total_interactions - b.total_interactions
            : b.total_interactions - a.total_interactions;
        }
        return 0; // Default case
      });
    
      setInteractions(sorted);
    };



// Cargar las interacciones completadas al abrir el modal
useEffect(() => {
  const fetchCompletedInteractions = async () => {
    try {
      const response = await axios.get(
        `${baseAPI}/expedients/done-interactions`
      );
      setCompletedInteractions(response.data.expedients);
    } catch (error) {
      console.error("Error fetching completed interactions:", error);
    }
  };

  if (modalOpen) {
    fetchCompletedInteractions();
  }
}, [modalOpen]);
    

    return (
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/* Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-6 w-full max-w-9xl mx-auto">
              {/* Welcome banner */}
              <WelcomeBanner />

              {/* Título de la tabla */}
              <header className="px-4 py-3 border-b border-gray-100 flex justify-between items-center">
  <h2 className="font-semibold text-gray-800 text-lg">
    📬 Interacciones Pendientes
  </h2>
  <div className="flex items-center space-x-4">
    <span className="text-sm text-gray-600">Pendientes: {totalUnreadCount}</span>
    <button
                onClick={() => setModalOpen(true)}
                className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
              >
                Ver Interacciones Completadas
              </button>
  </div>
</header>



              {/* Tabla de interacciones */}
              <div className="grid grid-cols-12 gap-4 mt-4">
                {loading ? (
                  <p className="text-center col-span-12">Cargando...</p>
                ) : (
                  <div className="col-span-12">
                    <div className="overflow-x-auto">
                      <table className="table-auto w-full text-left">
                        <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
                          <tr>
                            <th
                              className="px-3 py-2 cursor-pointer"
                              onClick={() => sortInteractions("createdAt")}
                            >
                              <div className="flex items-center">
                                Fecha Creación{" "}
                                {sortField === "createdAt" &&
                                  (sortOrder === "asc" ? "🔼" : "🔽")}
                              </div>
                            </th>
                            <th className="px-3 py-2">Cliente</th>
                            <th className="px-3 py-2">Deudor</th>
                            <th
                              className="px-3 py-2 cursor-pointer"
                              onClick={() => sortInteractions("importe_deuda")}
                            >
                              <div className="flex items-center">
                                Importe Deuda{" "}
                                {sortField === "importe_deuda" &&
                                  (sortOrder === "asc" ? "🔼" : "🔽")}
                              </div>
                            </th>
                            <th
                              className="px-3 py-2 cursor-pointer"
                              onClick={() =>
                                sortInteractions("total_interactions")
                              }
                            >
                              <div className="flex items-center">
                                Interacciones{" "}
                                {sortField === "total_interactions" &&
                                  (sortOrder === "asc" ? "🔼" : "🔽")}
                              </div>
                            </th>
                            <th className="px-3 py-2">Estado</th>

                            <th className="px-3 py-2">Acciones</th>
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-200">
                          {interactions.map((interaction) => (
                        <Interactions
                        key={interaction.expedient_id}
                        interaction={interaction}
                        toggleIsDone={toggleIsDone}
                      />
                      
                          ))}
                        </tbody>
                      </table>
                      {interactions.length === 0 && (
                        <div className="text-center text-gray-400 py-6">
                          No hay interacciones registradas.
                        </div>
                      )}
                    </div>
                  </div>
               
                
                )}
              </div>
              <div className="flex justify-center items-center mt-4 space-x-2">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 rounded ${
                      currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-500 text-white"
                    }`}
                  >
                    Anterior
                  </button>
                
                  <span className="text-gray-700">
                    Página {currentPage} de {totalPages}
                  </span>
                
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 rounded ${
                      currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-500 text-white"
                    }`}
                  >
                    Siguiente
                  </button>
                </div>
            </div>

          
            <CompletedInteractionsModal
  isOpen={modalOpen}
  onClose={() => setModalOpen(false)}
  interactions={completedInteractions}
  toggleIsDone={toggleIsDone}
/>

              
          </main>
          <Footer />
        </div>
      </div>
    );
  };



  export default InteractionsList;
